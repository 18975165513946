import React from 'react';
import { useSession, useQuery, LoadingPage, SelectList, FormNameProvider, PaginatorWithResult } from '../components';
import { convertQueryToForm, convertFormToGraphQl, getCurrentQueryFromLocation } from '../employees/utils';
import { gql } from '@apollo/client';
import { Field } from 'redux-form';
import styles from './Employees.scss';
import useReactRouter from 'use-react-router';
import { FilteringTag } from 'jbc-front/components/SearchForm';
import searchForm from '../employees/SearchForm';
import { BulkRequestForm as BulkRequest } from './BulkRequest';
import { BulkUpdateForm as BulkUpdate } from './BulkUpdate';

const EMPLOYEES = gql`
  query employees($limit: Int, $page: Int, $search: EmployeeSearchInput) {
    client {
      id
      employees(limit: $limit, page: $page, search: $search) {
        totalCount
        list {
          id
          commonId
          officeId
          staffCode
          firstName
          lastName
          firstNameKana
          lastNameKana
          email
          gender
          joinedAt
          retiredAt
          officeName
          employmentType
          occupation
          groups
          position
        }
      }
    }
  }
`;

const EMPLOYEES_EXCLUDE = gql`
  query employees($limit: Int, $page: Int, $search: EmployeeSearchInput, $excludeImplementationId: ID) {
    client {
      id
      employees(limit: $limit, page: $page, search: $search, excludeImplementationId: $excludeImplementationId) {
        totalCount
        list {
          id
          commonId
          officeId
          staffCode
          firstName
          lastName
          firstNameKana
          lastNameKana
          email
          gender
          joinedAt
          retiredAt
          officeName
          employmentType
          occupation
          groups
          position
        }
      }
    }
  }
`;

// TODO: いまは GraphQL の EmployeeStatus にマッピングしているけど、EmployeeStatus と別に RequestCheckStatus という Type が必要
const employmentStatuses = [
  { id: 'all', name: '在職中＋休職中' },
  { id: 'employed', name: '在職中' },
  { id: 'vacation', name: '休職中' }
];

const additionalParams = { embed: [] };

const searchFormName = 'employeeSearchTop';

const SearchForm = searchForm(searchFormName);

const SearchEmploymentStatusField = ({ input: inputProps, employmentStatuses, onEmploymentStatusChange }) => {
  return (
    <div className={styles.searchEmploymentStatusField}>
      {employmentStatuses.map(item => (
        <FilteringTag
          key={item.id}
          selected={item.id === inputProps.value}
          onClick={() => {
            const newValue = item.id;
            inputProps.onChange(newValue);
            inputProps.onBlur(newValue);
            onEmploymentStatusChange();
          }}>
          <div className={styles.employmentStatus}>{item.name}</div>
        </FilteringTag>
      ))}
    </div>
  );
};

export const Employees = ({ location: { search, pathname, state } }) => {
  const { user } = useSession();
  const formValues = convertQueryToForm(getCurrentQueryFromLocation({ search, pathname }, user));
  const variables = { excludeImplementationId: state?.selectedSurvey.id, ...convertFormToGraphQl(formValues) };
  const { data, loading } = useQuery(state === undefined ? EMPLOYEES : EMPLOYEES_EXCLUDE, {
    variables,
    fetchPolicy: 'no-cache'
  });

  if (loading) {
    return <LoadingPage />;
  }

  const disabledMessage = employee => {
    return !employee.email
      ? 'メールアドレスを入力してください。'
      : !employee.gender
        ? '性別を入力してください。'
        : !employee.commonId
          ? 'エラーが発生しました。お手数ですがサポート窓口までお問い合わせください。'
          : null;
  };
  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">従業員選択</h1>
      </div>
      <div className={styles.contentsWrap}>
        <SelectList
          mode="request"
          list={data?.client?.employees?.list ?? []}
          disabledMessage={disabledMessage}
          selectedInfo={({ commonId, officeId }) => ({
            commonId: commonId,
            officeId: officeId
          })}>
          {({ list, th, selected }) => {
            const { history } = useReactRouter();
            return (
              <div className="l-contents-wrap">
                <div className="l-wrap-xl">
                  <SearchForm
                    query={state === undefined ? EMPLOYEES : EMPLOYEES_EXCLUDE}
                    variables={variables}
                    additionalParams={additionalParams}
                    statusField={handleSubmit => (
                      <Field
                        name="status"
                        component={SearchEmploymentStatusField}
                        employmentStatuses={employmentStatuses}
                        onEmploymentStatusChange={handleSubmit}
                      />
                    )}
                  />
                  {!data?.client ? (
                    <div className="l-wrap-xs u-pt100">
                      <div className="l-box-message">
                        <p className="m-title-sub u-pt0 u-ta-c">従業員を取得できませんでした。</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="l-overflow-scroll">
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              {th}
                              <th className={styles.staffcodeColumn}>スタッフコード</th>
                              <th className={styles.nameColumn}>氏名</th>
                              <th className={styles.officeColumn}>適用事業所</th>
                              <th className={styles.employmentStatusColumn}>雇用形態</th>
                              <th className={styles.groupColumn}>グループ</th>
                              <th className={styles.positionColumn}>役職</th>
                              <th className={styles.joinedAtColumn}>入社日</th>
                            </tr>
                          </thead>
                          <tbody>
                            {list.length > 0 &&
                              list.map(({ item: employee, td }) => (
                                <tr key={`${employee.id}`} className={styles.tr + ' table-hover'}>
                                  {td}
                                  <td>{employee.staffCode || '-'}</td>
                                  <td>
                                    {employee.lastName} {employee.firstName}
                                  </td>
                                  <td>{employee.officeName}</td>
                                  <td>{employee.employmentType || '-'}</td>
                                  <td>
                                    {employee.groups.length > 0
                                      ? employee.groups.map(group => (
                                          <span key={group}>
                                            {group}
                                            <br />
                                          </span>
                                        ))
                                      : '-'}
                                  </td>
                                  <td>{employee.position || '-'}</td>
                                  <td>{employee.joinedAt || '-'}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <FormNameProvider formName={searchFormName}>
                        <PaginatorWithResult count={data?.client?.employees?.totalCount} isLoading={loading} />
                      </FormNameProvider>
                      {list.length > 0 && (
                        <div className="l-contents-wrap l-flex">
                          {state === undefined ? (
                            <BulkRequest history={history} selected={selected} />
                          ) : (
                            <BulkUpdate history={history} selected={selected} selectedSurvey={state.selectedSurvey} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          }}
        </SelectList>
      </div>
    </>
  );
};

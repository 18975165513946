import { combineReducers } from 'redux';
import { reducer as form, FormStateMap } from 'redux-form';
import { reducer as formErrors } from 'jbc-front/components/FormErrors';

export interface RootState {
  form: FormStateMap;
  formErrors: any;
}

const rootReducer = combineReducers<RootState>({
  form,
  formErrors
});

export default rootReducer;
